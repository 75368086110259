<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      table-height="calc(100vh - 205px)"
      api-url="/payment-history"
      @showDialogCreate="showDialogCreate"
      @showDialogLog="showDialogLog"
    ></custom-table>

    <v-dialog v-model="createDialog" max-width="640px" @input="refreshData">
      <PaymentCreate
        v-if="createDialog"
        :select-item="selectItem"
        @close="hideDialogCreate"
      />
    </v-dialog>

    <v-dialog v-model="logDialog" max-width="960px">
      <PaymentHistoryLog
        v-if="logDialog"
        :select-item="selectItem"
        @close="hideDialogLog"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_ADMIN_PAYABLE,
  SUPPLIER_TYPE_OPTIONS,
  IMAGE_OPTIONS,
} from "@/libs/const";
export default {
  name: "PaymentHistory",
  components: {
    PaymentCreate: () => import("@/components/debt/PaymentCreate"),
    PaymentHistoryLog: () => import("@/components/debt/PaymentHistoryLog"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_ADMIN_PAYABLE,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    createDialog: false,
    selectItem: {},
    logDialog: false,
  }),
  computed: {},
  created() {
    const paymentMethodOptions = [
      {
        value: 1,
        text: this.$t("labels.payment_cash"),
      },
      {
        value: 2,
        text: this.$t("labels.payment_transfer"),
      },
    ];
    const supplierTypeOptions = [...SUPPLIER_TYPE_OPTIONS].map((opt) => ({
      text: this.$t(`labels.supplier_type_${opt}`),
      value: opt,
    }));
    const imageOptions = [...IMAGE_OPTIONS];
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.supplier_code"),
        placeholder: this.$t("labels.supplier_code"),
        name: "supplier_code",
        hasSort: true,
        sortName: "supplier_code",
        key: "supplier_code",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.type"),
        placeholder: this.$t("labels.type"),
        name: "supplier_type",
        hasSort: false,
        sortName: "supplier_type",
        key: "supplier_type",
        options: supplierTypeOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "supplier_name",
        hasSort: true,
        sortName: "supplier_name",
        key: "supplier_name",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_value"),
        placeholder: this.$t("labels.payment_value"),
        name: "payment_value",
        hasSort: true,
        sortName: "payment_value",
        key: "payment_value",
        required: true,
        action: "showDialogLog",
        actionPermissions: [],
        actionModules: [],
      },
      {
        type: "select-filter",
        label: this.$t("labels.payment_method"),
        placeholder: this.$t("labels.payment_method"),
        name: "payment_method",
        hasSort: false,
        sortName: "payment_method",
        key: "payment_method",
        options: paymentMethodOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.payment_code"),
        placeholder: this.$t("labels.payment_code"),
        name: "payment_code",
        hasSort: true,
        sortName: "payment_code",
        key: "payment_code",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.payment_image"),
        placeholder: this.$t("labels.payment_image"),
        name: "payment_image",
        hasSort: false,
        sortName: "payment_image",
        key: "payment_image",
        options: imageOptions,
        required: true,
        component: "ImageMultiple",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.payment_date"),
        placeholder: this.$t("labels.payment_date"),
        name: "payment_date",
        hasSort: true,
        sortName: "payment_date",
        key: "payment_date",
        dateOnly: true,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: true,
        sortName: "note",
        key: "note",
        required: true,
      },
      {
        type: "button",
        label: this.$t("labels.note"),
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: "showDialogCreate",
        actionColor: "warning",
        actionLabel: this.$t("labels.edit"),
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    showDialogCreate(item) {
      this.selectItem = { ...item };
      this.createDialog = true;
    },
    hideDialogCreate() {
      this.selectItem = {};
      this.createDialog = false;
      this.refreshData();
    },
    showDialogLog(item) {
      this.selectItem = { ...item };
      this.logDialog = true;
    },
    hideDialogLog() {
      this.selectItem = {};
      this.logDialog = false;
    },
    refreshData() {
      this.$refs.customTable.getList();
    },
  },
};
</script>

<style scoped></style>
